import React from "react"
import { Form } from "@ant-design/compatible"
import "@ant-design/compatible/assets/index.css"
import { Button, Input } from "antd"
import {
  CheckOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
} from "@ant-design/icons"
import { urlParams } from "../../utils/browser"

const inputProps = (name, icon) => {
  return {
    addonBefore: icon ? icon : false,
    allowClear: true,
    placeholder: name ? `${name}` : false,
    size: "large",
  }
}

const hiddenItemProps = {
  style: {
    display: `none`,
  },
}

export const Name = props => {
  const placeholder = props.placeholder || "Name"
  const required = props.required || true
  const icon = props.icon || <UserOutlined />

  const itemProps = {
    validateStatus: props.validator ? "error" : "",
    help: props.validator || "",
  }

  return (
    <Form.Item {...itemProps}>
      {props.decorator("name", {
        rules: [
          {
            required: required || false,
            message: "Please input your name!",
          },
        ],
      })(<Input label="Name" {...inputProps(placeholder, icon)} />)}
    </Form.Item>
  )
}

export const Email = props => {
  const placeholder = props.placeholder || "Email"
  const required = props.required || true
  const icon = props.icon || <MailOutlined />

  const itemProps = {
    validateStatus: props.validator ? "error" : "",
    help: props.validator || "",
  }

  return (
    <Form.Item {...itemProps}>
      {props.decorator("email", {
        rules: [
          {
            required: required,
            message: "Please input your email!",
          },
          {
            type: "email",
            message: "This doesn't look like a valid email!",
          },
        ],
      })(<Input label="Email" {...inputProps(placeholder, icon)} />)}
    </Form.Item>
  )
}

export const Phone = props => {
  const placeholder = props.placeholder || "Phone Number"
  const required = props.required || false
  const icon = props.icon || <PhoneOutlined />

  const itemProps = {
    validateStatus: props.validator ? "error" : "",
    help: props.validator || "",
  }

  return (
    <Form.Item {...itemProps}>
      {props.decorator("phone", {
        rules: [
          {
            required: required,
            message: "Please input your phone number!",
          },
        ],
      })(<Input label="Phone Number" {...inputProps(placeholder, icon)} />)}
    </Form.Item>
  )
}

export const Message = props => {
  const placeholder = props.placeholder || "How can we help you?"
  const required = props.required || false
  const rows = props.rows || 4

  const itemProps = {
    validateStatus: props.validator ? "error" : "",
    help: props.validator || "",
  }

  const inputProps = {
    allowClear: true,
    placeholder: placeholder,
    rows: rows,
    spellCheck: true,
  }

  return (
    <Form.Item {...itemProps} style={props.style}>
      {props.decorator("message", {
        rules: [
          {
            required: required,
            message: "Please contextualize your needs with a brief message.",
          },
        ],
      })(<Input.TextArea label="Message" {...inputProps} />)}
    </Form.Item>
  )
}

export const AutomationID = props => {
  const required = props.required || true

  return (
    <Form.Item {...hiddenItemProps}>
      {props.decorator("automation_id", {
        initialValue: props.automationId,
        rules: [
          {
            required: required,
          },
        ],
      })(<Input />)}
    </Form.Item>
  )
}

export const FormName = props => {
  const required = props.required || true

  return (
    <Form.Item {...hiddenItemProps}>
      {props.decorator("form_name", {
        initialValue: props.formName,
        rules: [
          {
            required: required,
          },
        ],
      })(<Input />)}
    </Form.Item>
  )
}

export const TenantOverride = ({ decorator }) => {
  return (
    <Form.Item {...hiddenItemProps}>
      {decorator("tenant_override", {
        initialValue: process.env.GATSBY_TENANT_OVERRIDE,
      })(<Input />)}
    </Form.Item>
  )
}

export const SyncDeal = ({ decorator, syncDeal }) => {
  return (
    <Form.Item {...hiddenItemProps}>
      {decorator("sync_deal", {
        initialValue: syncDeal === false ? false : true,
      })(<Input />)}
    </Form.Item>
  )
}

export const ActiveCampaignValues = ({
  automationId,
  decorator,
  formName,
  syncDeal,
}) => (
  <>
    <AutomationID automationId={automationId} decorator={decorator} />
    <FormName formName={formName} decorator={decorator} />
    <TenantOverride decorator={decorator} />
    <SyncDeal syncDeal={syncDeal} decorator={decorator} />
  </>
)

export const Submit = props => {
  const buttonProps = {
    block: props.block || true,
    disabled: props.disabled,
    htmlType: "submit",
    icon: props.icon || <CheckOutlined />,
    id: props.id || "",
    size: "large",
    type: props.type || "primary",
  }

  const buttonText = props.text || "Submit"

  return (
    <Form.Item>
      <Button {...buttonProps}>{buttonText}</Button>
    </Form.Item>
  )
}

export const UTMMedium = ({ decorator }) => {
  const params = urlParams()
  return (
    <Form.Item {...hiddenItemProps}>
      {decorator("utm_medium", {
        initialValue: (params && params.get("utm_medium")) || undefined,
      })(<Input />)}
    </Form.Item>
  )
}

export const UTMSource = ({ decorator }) => {
  const params = urlParams()
  return (
    <Form.Item {...hiddenItemProps}>
      {decorator("utm_source", {
        initialValue: (params && params.get("utm_source")) || undefined,
      })(<Input />)}
    </Form.Item>
  )
}

export const UTMCampaign = ({ decorator }) => {
  const params = urlParams()
  return (
    <Form.Item {...hiddenItemProps}>
      {decorator("utm_campaign", {
        initialValue: (params && params.get("utm_campaign")) || undefined,
      })(<Input />)}
    </Form.Item>
  )
}

export const UTMParams = ({ decorator }) => (
  <>
    <UTMMedium decorator={decorator} />
    <UTMSource decorator={decorator} />
    <UTMCampaign decorator={decorator} />
  </>
)
